.document-repository-tabs {
    &.Mui-selected {
        color: #5AB9F9;
        border-bottom: 3px solid #5AB9F9;
    }

    outline: 0;
    border: 0;
    margin: 12px 18px;
    cursor: pointer;
    font-weight: 500;
    padding: 12px 3px;
    color: #727272;
    font-size: 16px;
}