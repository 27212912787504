@import "../../../../assets/scss/variables";

.document-repository-tabs {
  &.Mui-selected {
    color: #5ab9f9;
    border-bottom: 3px solid #5ab9f9;
  }

  outline: 0;
  border: 0;
  margin: 12px 18px;
  cursor: pointer;
  font-weight: 500;
  padding: 12px 3px;
  color: #727272;
  font-size: 16px;
}

.input-drag-and-drop-field {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.publish-button {
  background: #5ab9f9;
  box-shadow: 0 10px 25px rgba(90, 185, 249, 0.25);
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  padding: 8px 24px;
  text-align: center;
  color: #ffffff;
}

.request-tabs {
  &.Mui-selected {
    color: #5ab9f9;
    background: #f5fbff;
    border: 1px solid rgba(90, 185, 249, 0.25);
    border-radius: 8px;
    box-shadow: 0 10px 25px rgba(120, 144, 156, 0.2);
  }

  outline: 0;
  border: 0;
  margin: 10px 5px 30px 5px;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: 0.02857em;
  padding: 12px;
  color: #727272;
  font-size: 16px;
  background: #f6f7fb;
  border-radius: 8px;

  &:last-child {
    margin-right: 0;
  }
}

.card-right {
  background: #f5fbff;
  padding: 40px 0 70px 0 !important;
  border-radius: 10px;
}

.body-head {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #263238;
}

.additional-body-head {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #727272;
  padding-left: 40px;
}

.document-card {
  background: #ffffff;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  align-items: center;
  letter-spacing: 0.15px;
  color: #263238;
  padding: 8px 25px;
  margin: 12px 0;
}

.additional-document-text {
  padding: 10px 14px !important;
  background: #ffffff;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  align-items: center;
  letter-spacing: 0.15px;
  color: #263238;
  margin: 20px auto 10px;
}

.card-vetting,
.card-additional,
.card-form,
.card-additional-comments {
  background: #f5fbff;
  border-radius: 17.5px;
  margin-top: 4px;
  padding: 0;
  width: calc(100% - 1rem);
}

.card-head-table {
  background: #e9f6ff;
  margin-bottom: 40px;
  border-radius: 17.5px 17.5px 0 0;
}

.card-footer {
  background: #e9f6ff;
  border-radius: 17.5px;
}

.card-header-text {
  font-weight: 500;
  font-size: 16px;
  color: #212121;
  padding: 11px 0 11px 0;
}

// dropdown
.timePeriod > .MuiSelect-select {
  padding: 7px 15px;
  background-color: white;
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
  color: #727272;
}

.issue > .MuiSelect-select {
  padding: 13px 15px;
  background-color: white;
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
  color: #727272;
}

.headerTimePeriod > .MuiOutlinedInput-root {
  background-color: white;
  border-radius: 2px;
  font-weight: 500;
  font-size: 12px;
  color: #727272;
}

.action-icons {
  padding: 12px;
  border-radius: 8px;
  color: #333333;
}

.MuiButtonBase-root {
  text-transform: none;
}

.upload-button {
  color: white;
  padding: 8px 20px;
  background: #5ab9f9;
  box-shadow: 0 10px 25px rgba(90, 185, 249, 0.25);
  border-radius: 6px;
}

.instructions {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #727272;
}

.dotted-card {
  background: #ffffff;
  border: 1px dashed #5ab9f9;
  border-radius: 12px;
  padding: 40px 10px 10px 10px;
}
.uploadBtn {
  min-height: 40px !important;
}
.uploadBtn:hover {
  background: white !important;
  color: #5ab9f9;
}

@media (max-width: 768px) {
  .btn-groups {
    display: flex;
    flex-direction: row;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    background: white;
    width: 100%;
    justify-content: space-between;
    padding: 5px 5px 5px 0;
  }

  .tab-container .MuiTabs-scroller {
    overflow-x: scroll !important;
  }
  .card-vetting {
    width: 100% !important;
  }
}
