@import '../../../../assets/scss/variables';

.publish-button {
  background: #5AB9F9;
  box-shadow: 0 10px 25px rgba(90, 185, 249, 0.25);
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  padding: 8px 24px;
  text-align: center;
  color: #FFFFFF;
}

.request-tabs {
  &.Mui-selected {
    color: #5AB9F9;
    background: #F5FBFF;
    border: 1px solid rgba(90, 185, 249, 0.25);
    border-radius: 8px;
    box-shadow: 0 10px 25px rgba(120, 144, 156, 0.2);
  }

  outline: 0;
  border: 0;
  margin: 12px 16px;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: 0.02857em;
  padding: 12px;
  color: #727272;
  font-size: 16px;
  background: #F6F7FB;
  border-radius: 8px;

  &:last-child {
    margin-right: 0;
  }
}

.card-right {
  background: #F5FBFF;
  padding: 40px 0 70px 0 !important;
}

//.request-details-box {
  //font-weight: 500;
  //font-size: 18px;
  //letter-spacing: 0.15px;
  //color: #212121;
//}

//.request-details-box-heading {
  //font-weight: 400;
  //font-size: 14px;
  //color: #727272;
//}

.body-head {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #263238;
}

.additional-body-head {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #727272;
  padding-left: 40px;
}

.document-card {
  background: #FFFFFF;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  align-items: center;
  letter-spacing: 0.15px;
  color: #263238;
  padding: 8px 25px;
  margin: 12px 0;
}

.additional-document-text {
  padding: 10px 14px !important;
  background: #FFFFFF;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  align-items: center;
  letter-spacing: 0.15px;
  color: #263238;
  margin: 20px auto 10px;
}

.card-vetting,
.card-additional {
  background: #F5FBFF;
  border-radius: 17.5px;
  margin-top: 4px;
  padding: 0;
  width: calc(100% - 1rem);
}

.card-head-table {
  background: #E9F6FF;
  margin-bottom: 40px;
  border-radius: 17.5px 17.5px 0 0;
}

.card-footer-table {
  background: #E9F6FF;
  box-shadow: 0px -1px 1px rgba(162, 181, 210, 0.125);
  border-radius: 0px 0px 18px 18px;
}

.card-header-text {
  font-weight: 500;
  font-size: 16px;
  color: #212121;
  padding: 11px 0 11px 0;
}

// dropdown
.timePeriod>.MuiSelect-select {
  padding: 0px !important;
  background-color: white;
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
  color: #727272;
}

.issue>.MuiSelect-select {
  padding: 13px 15px;
  background-color: white;
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
  color: #727272;
}

.headerTimePeriod>.MuiOutlinedInput-root {
  background-color: white;
  border-radius: 2px;
  font-weight: 500;
  font-size: 12px;
  color: #727272;
}

.warning-checkbox {
  background: #FEFBEC !important;
  border: 1px solid #F4D356;
  border-radius: 8px;
  margin: 0 0 10px 0;
  padding: 8px 20px;
}

.error-checkbox {
  background: #F6D3D3 !important;
  border: 1px solid red;
  border-radius: 8px;
  margin: 0 0 10px 0;
  padding: 8px 20px;
}

.action-icons {
  padding: 12px;
  border-radius: 8px;
  color: #333333;
}

.MuiButtonBase-root {
  text-transform: none;
}

.upload-button {
  color: white;
  padding: 8px 20px;
  background: #5AB9F9;
  box-shadow: 0 10px 25px rgba(90, 185, 249, 0.25);
  border-radius: 6px;
}

.instructions {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #727272;
}

.dotted-card {
  background: #FFFFFF;
  border: 1px dashed #5AB9F9;
  border-radius: 12px;
  padding: 40px 10px 10px 10px;
}

.profile-card {
  background: #F5FBFF;
  border: 1px dashed #DADADA;
  border-radius: 12px;
  padding: 16px 24px;
}

.profile-con {
  background-color: #ffffff;
  text-align: center;
  width: 95px;
  height: 95px;
  border-radius: 50%;
}

.profile-con img {
  border-radius: inherit;
}

@media (max-width: 768px) {
  .card-vetting {
    padding: 0 0 80px 0;
  }
}
