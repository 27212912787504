@import "../../../../assets/scss/variables";

.publish-button {
  background: #5ab9f9;
  box-shadow: 0 10px 25px rgba(90, 185, 249, 0.25);
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  padding: 8px 24px;
  text-align: center;
  color: #ffffff;
}

.request-tabs {
  &.Mui-selected {
    color: #5ab9f9;
    background: #f5fbff;
    border: 1px solid rgba(90, 185, 249, 0.25);
    border-radius: 8px;
    box-shadow: 0 10px 25px rgba(120, 144, 156, 0.2);
  }

  outline: 0;
  border: 0;
  margin: 12px 16px;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: 0.02857em;
  padding: 12px;
  color: #727272;
  font-size: 16px;
  background: #f6f7fb;
  border-radius: 8px;

  &:last-child {
    margin-right: 0;
  }
}

.card-right {
  background: #f5fbff;
  padding: 40px 0 70px 0 !important;
}

.request-details-box {
  //font-weight: 500;
  //font-size: 18px;
  //letter-spacing: 0.15px;
  //color: #212121;
}

.request-details-box-heading {
  //font-weight: 400;
  //font-size: 14px;
  //color: #727272;
}

.body-head {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #263238;
}

.additional-body-head {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #727272;
  padding-left: 40px;
}

.document-card {
  background: #ffffff;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  align-items: center;
  letter-spacing: 0.15px;
  color: #263238;
  padding: 8px 25px;
  margin: 12px 0;
}

.additional-document-text {
  padding: 10px 14px !important;
  background: #ffffff;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  align-items: center;
  letter-spacing: 0.15px;
  color: #263238;
  margin: 20px auto 10px;
}

.card-vetting,
.card-additional,
.card-form,
.card-additional-comments {
  background: #f5fbff;
  border-radius: 17.5px;
  margin-top: 4px;
  padding: 0;
  width: calc(100% - 1rem);
}
.card-additional{
  margin-top: 0px !important;
}

.card-head-table {
  background: #e9f6ff;
  margin-bottom: 40px;
  border-radius: 17.5px 17.5px 0 0;
}

.card-header-text {
  font-weight: 500;
  font-size: 16px;
  color: #212121;
  padding: 11px 0 11px 0;
}

// dropdown
.timePeriod > .MuiSelect-select {
  padding: 7px 15px;
  background-color: white;
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
  color: #727272;
}

.headerTimePeriod > .MuiOutlinedInput-root {
  background-color: white;
  border-radius: 2px;
  font-weight: 500;
  font-size: 12px;
  color: #727272;
}

.action-icons {
  padding: 12px;
  border-radius: 8px;
  color: #333333;
}

.MuiButtonBase-root {
  text-transform: none;
}

.upload-button {
  color: white;
  padding: 8px 20px;
  background: #5ab9f9;
  box-shadow: 0 10px 25px rgba(90, 185, 249, 0.25);
  border-radius: 6px;
}

.instructions {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #727272;
}

.dotted-card {
  background: #ffffff;
  border: 1px dashed #5ab9f9;
  border-radius: 12px;
  padding: 40px 10px 10px 10px;
}

.timePeriod > .MuiSelect-select {
  padding: 7px 15px !important;
  background-color: white;
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
  color: #727272;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 150px;
}

@media (max-width: 768px) {
  .btn-groups {
    display: flex;
    flex-direction: row;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    background: white;
    box-shadow: 0px -4px 7px #e6e6e6;
    border-radius: 15px 15px 0px 0px;
    width: 100%;
    justify-content: space-evenly !important;
    padding: 5px !important;
    padding-left: 0 !important;
  }

  .tab-main-con .MuiTabs-scroller {
    overflow-x: scroll !important;
  }
}
